import classNames from 'classnames'
import { FaXTwitter } from 'react-icons/fa6'
import { urls } from '../../urls'
import { ButtonLink, ExternalButtonLink } from './Button/ButtonLink'
import { InternalLink } from './Link'
import { LinkBox, linkBoxLinkClassName } from './LinkBox'

const Logo = () => {
  return (
    <LinkBox className="flex flex-col items-start sm:flex-row sm:items-end sm:pb-3">
      {/* eslint-disable-next-line */}
      <img alt="DoTheySupportIt Logo" className="block w-16" src={'/logo/logo.svg'} />
      <div className="sm:ml-2">
        <InternalLink
          className={classNames(
            linkBoxLinkClassName,
            'text-sm font-bold tracking-tighter sm:text-sm md:text-base',
          )}
          href={urls.homepage()}
        >
          DoTheySupportIt
        </InternalLink>
      </div>
    </LinkBox>
  )
}

export const Footer = () => (
  <footer className="mx-auto w-full max-w-6xl px-4 pb-4 pt-16" role="contentinfo">
    <hr className="mx-4 mb-12 border-t border-gray-600" />
    <div>
      <div className="flex flex-col content-between sm:flex-row sm:items-end sm:justify-between">
        <Logo />
        <div className="flex justify-between sm:flex-initial">
          <ButtonLink
            size="custom"
            className="py-3 pl-0 pr-3 text-xs sm:px-3 sm:text-sm md:text-base"
            variant="link"
            href={urls.privacyPolicy()}
          >
            Privacy Policy
          </ButtonLink>
          <ButtonLink
            size="custom"
            className="p-3 text-xs sm:px-3 sm:text-sm md:text-base"
            variant="link"
            href={urls.termsAndConditions()}
          >
            Terms and Conditions
          </ButtonLink>
          <ButtonLink
            size="custom"
            className="p-3 text-xs sm:px-3 sm:text-sm md:text-base"
            variant="link"
            href={urls.faq()}
          >
            FAQs
          </ButtonLink>
          <ExternalButtonLink
            size="custom"
            className="p-3 text-xs sm:px-3 sm:text-sm md:text-base"
            variant="link"
            href="https://x.com/DoTheySupportIt"
            aria-label="Twitter"
          >
            <FaXTwitter fontSize="16px" />
          </ExternalButtonLink>
        </div>
      </div>
      <p className="text-sm">Made to help create more economic freedom.</p>
    </div>
  </footer>
)
